import * as i0 from '@angular/core';
import { ChangeDetectorRef, TemplateRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, ContentChild, HostBinding, Directive, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl } from '@angular/forms';
import * as i2 from '@taiga-ui/cdk';
import { AbstractTuiControl, tuiIsNativeFocused, AbstractTuiValueTransformer, tuiAsFocusableItemAccessor, tuiAsControl, TuiActiveZoneModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_TEXTFIELD_SIZE, tuiAsDataListHost, TuiDataListDirective, TuiHostedDropdownComponent, TuiPrimitiveTextfieldComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule, TuiTextfieldComponent } from '@taiga-ui/core';
import { TUI_VALUE_ACCESSOR_PROVIDER, FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/kit/providers';
import { CommonModule } from '@angular/common';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
class TuiInputComponent extends AbstractTuiControl {
  constructor(control, cdr, textfieldSize, valueTransformer) {
    super(control, cdr, valueTransformer);
    this.textfieldSize = textfieldSize;
    this.open = false;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    return this.computedDisabled || !this.textfield ? null : this.textfield.nativeFocusableElement;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement) || !!this.hostedDropdown && this.hostedDropdown.focused;
  }
  get canOpen() {
    return this.interactive && !!this.datalist;
  }
  onValueChange(value) {
    this.value = value;
    this.open = true;
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  handleOption(item) {
    this.setNativeValue(String(item));
    this.focusInput();
    this.value = String(item);
    this.open = false;
  }
  getFallbackValue() {
    return '';
  }
  focusInput(preventScroll = false) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll
      });
    }
  }
  setNativeValue(value) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.value = value;
    }
  }
}
TuiInputComponent.ɵfac = function TuiInputComponent_Factory(t) {
  return new (t || TuiInputComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE), i0.ɵɵdirectiveInject(AbstractTuiValueTransformer, 8));
};
TuiInputComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputComponent,
  selectors: [["tui-input"]],
  contentQueries: function TuiInputComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
    }
  },
  viewQuery: function TuiInputComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiHostedDropdownComponent, 5);
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hostedDropdown = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  features: [i0.ɵɵProvidersFeature([TUI_VALUE_ACCESSOR_PROVIDER, tuiAsFocusableItemAccessor(TuiInputComponent), tuiAsDataListHost(TuiInputComponent), tuiAsControl(TuiInputComponent)], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 4,
  vars: 11,
  consts: [[1, "t-hosted", 3, "openChange", "tuiActiveZoneChange", "canOpen", "content", "open"], ["automation-id", "tui-input__textfield", 1, "t-textfield", 3, "valueChange", "disabled", "focusable", "invalid", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "value"]],
  template: function TuiInputComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 0);
      i0.ɵɵtwoWayListener("openChange", function TuiInputComponent_Template_tui_hosted_dropdown_openChange_0_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.open, $event) || (ctx.open = $event);
        return $event;
      });
      i0.ɵɵlistener("tuiActiveZoneChange", function TuiInputComponent_Template_tui_hosted_dropdown_tuiActiveZoneChange_0_listener($event) {
        return ctx.onActiveZone($event);
      });
      i0.ɵɵelementStart(1, "tui-primitive-textfield", 1);
      i0.ɵɵlistener("valueChange", function TuiInputComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
        return ctx.onValueChange($event);
      });
      i0.ɵɵprojection(2);
      i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("canOpen", ctx.canOpen)("content", ctx.datalist || "");
      i0.ɵɵtwoWayProperty("open", ctx.open);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.computedFocusable)("invalid", ctx.computedInvalid)("nativeId", ctx.nativeId)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("value", ctx.value);
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiPrimitiveTextfieldComponent, i2.TuiActiveZoneDirective, i1.TuiPrimitiveTextfieldDirective],
  styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}._disabled[_nghost-%COMP%]{pointer-events:none}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input',
      templateUrl: './input.template.html',
      styleUrls: ['./input.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TUI_VALUE_ACCESSOR_PROVIDER, tuiAsFocusableItemAccessor(TuiInputComponent), tuiAsDataListHost(TuiInputComponent), tuiAsControl(TuiInputComponent)],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i3.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }, {
      type: i2.AbstractTuiValueTransformer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [AbstractTuiValueTransformer]
      }]
    }];
  }, {
    hostedDropdown: [{
      type: ViewChild,
      args: [TuiHostedDropdownComponent]
    }],
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }]
  });
})();
class TuiInputDirective extends AbstractTuiTextfieldHost {
  onValueChange(value) {
    this.host.onValueChange(value);
  }
}
TuiInputDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputDirective_BaseFactory;
  return function TuiInputDirective_Factory(t) {
    return (ɵTuiInputDirective_BaseFactory || (ɵTuiInputDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputDirective)))(t || TuiInputDirective);
  };
})();
TuiInputDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputDirective,
  selectors: [["tui-input"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input',
      providers: [tuiAsTextfieldHost(TuiInputDirective)]
    }]
  }], null, null);
})();
class TuiInputModule {}
TuiInputModule.ɵfac = function TuiInputModule_Factory(t) {
  return new (t || TuiInputModule)();
};
TuiInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputModule
});
TuiInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiActiveZoneModule, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiActiveZoneModule, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule],
      declarations: [TuiInputComponent, TuiInputDirective],
      exports: [TuiInputComponent, TuiInputDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiInputComponent, TuiInputDirective, TuiInputModule };
